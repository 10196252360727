.App {
  &__header,
  &__content,
  &__footer {
    background-color: #fff;
  }

  &__header,
  &__content {
    display: flex;
    justify-content: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    padding-top: 2rem;
  }

  &__footer {
    text-align: center;
  }
}

@media (max-width: 690px) {
  .App {
    &__header {
      display: flex;
      justify-content: space-around;
    }
  }
}