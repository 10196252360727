.Logo {
  display: flex;
  flex-direction: column;
  font-family: 'Gothic A1';
  text-align: center;

  &__title {
    font-size: 1.5rem;
    color: #a69432;
  }

  &__description {
    font-size: 0.75rem;
  }
}

@media (max-width: 690px) {
  .Logo {
    display: none !important;
  }
}